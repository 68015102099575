import React from 'react';
import './Form.css';

function InvestorForm(props){
    function handleSubmit(event)
    {
        event.preventDefault();

        const formData = {
                currenttime: Date.now(),
                fullname: document.getElementById('fullname').value,
                email: document.getElementById('email').value,
                phone: document.getElementById('phone').value,
                budget: document.getElementById('budget').value
              };

        console.log(formData);
        props.onInvestorFormCompleted(formData)
        props.onCancel()
    }

    return(
    <div>
        <div className="modal-content">
            <span className="close" onClick={props.onCancel}>
                    &times;
            </span>
            <h2>Your Details</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                        <label htmlFor="fullname"> Full name:</label>
                        <input type="text" id="fullname" name="fullname" />
                </div>

                <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" />
                </div>

                <div className="form-group">
                        <label htmlFor="phone">Telephone Number:</label>
                        <input type="tel" id="phone" name="phone" />
                    </div>

                <div className="form-group">
                        <label htmlFor="budget">Budget: </label>
                        <input type="number" id="budget" name="budget"/>
                </div>

                <div className="form-group" style={{ justifyContent: 'center' }}>
                        <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    </div>
    );
};

export default InvestorForm