
import React from 'react';
import './Footer.css';


function Footer(){
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} MJM Real Properties Limited</p>
      <p>Comapny No: 15073934</p>
    </footer>
  );
};

export default Footer;