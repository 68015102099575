import React, { useState } from 'react';
import './Form.css';


function SellerForm(props){

    const [prefferedEmail, setIsPrefferedEmail] = useState(false);
    const [prefferedPhone,setIsPrefferedPhone]=useState(false)

    function handleSubmit(event)
    {
        event.preventDefault();

        const formData = {
                currenttime: Date.now(),
                fullname: document.getElementById('fullname').value,
                email: document.getElementById('email').value,
                phone: document.getElementById('phone').value,
                preffered_email: document.getElementById('prefferedEmail').value,
                preffered_phone: document.getElementById('prefferedPhone').value,
              };

        console.log(formData);
        props.onSellerFormCompleted(formData)
        props.onCancel()
    }

    return(
        <div>
            <div className="modal-content">
                <span className="close" onClick={props.onCancel}>
                    &times;
                </span>
                <h2>Your Contact Details</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="fullname"> Full name:</label>
                        <input type="text" id="fullname" name="fullname" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Telephone Number:</label>
                        <input type="tel" id="phone" name="phone" />
                    </div>

                    <div className="form-group" style={{ justifyContent: 'left' }}>
                        <div style={{ justifyContent: 'space-around',display:'flex',flexDirection:'row' }}>
                            <label htmlFor="prefferedContact">Preffered Contact:</label>
                            <div className='checkbox-group'>
                                <input type="checkbox" checked={prefferedEmail} onChange={() => setIsPrefferedEmail(!prefferedEmail)} id="prefferedEmail" name="prefferedEmail" />
                                <label htmlFor="prefferedEmail">Email</label>
                            </div>
                            <div className='checkbox-group'>
                                <input type="checkbox" checked={prefferedPhone} onChange={() => setIsPrefferedPhone(!prefferedPhone)} id="prefferedPhone" name="prefferedPhone" />
                                <label htmlFor="prefferedPhone">Telephone</label>
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div className="form-group" style={{ justifyContent: 'center' }}>
                        <button type="submit">Submit</button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default SellerForm