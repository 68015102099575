import React from "react";
import './Navbar.css';
import logo from '../resources/logo1.png';

function NavBar(){
    return(
        <nav>
            <div className="logo">
                <img src = {logo} alt='MJM Real Properties Ltd'></img>
            </div>
        </nav>
    );
}

export default NavBar;