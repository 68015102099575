import React, { useState } from 'react';

import './App.css';

import NavBar from './components/Navbar';
import Footer from './components/Footer';
import SellerForm from './components/SellerForm';
import Backdrop from './components/Backdrop';
import InvestorForm from './components/InvestorForm';

function App() {
  const [showSellerModal, setShowSellerModal] = useState(false);
  const [showInvestorModal, setShowInvestorModal] = useState(false);

  const openSellerModal = () => {
    closeInvestorModal();
    setShowSellerModal(true);
  };

  const closeSellerModal = () => {
    setShowSellerModal(false);
  };

  const openInvestorModal = () => {
    closeSellerModal();
    setShowInvestorModal(true);
  };

  const closeInvestorModal = () => {
    setShowInvestorModal(false);
  };

  const closeModals =()=>{
    closeSellerModal()
    closeInvestorModal()
  }

  function newInvestorFormHandler(investorFormData){
    fetch(
      'https://mjm-real-properties-default-rtdb.firebaseio.com/investors.json',
      {
        method:'POST',
        body: JSON.stringify(investorFormData),
        headers:{
          'Content-Type':'application/json'
        }
      }
      );
  }

  function newSellerForm(sellerFormData)
  {
    fetch(
      'https://mjm-real-properties-default-rtdb.firebaseio.com/sellers.json',
      {
        method:'POST',
        body: JSON.stringify(sellerFormData),
        headers:{
          'Content-Type':'application/json'
        }
      }
      );
  }

  return (
    <div>
      <NavBar/>

      <div className="container">
        <h1>Discover our expertise in expediting house sales,</h1>
        <p>connecting you with a vast pool of investors ready to buy your property right away.</p>
        <br/>
        <br/>
        <div className='button-container'>
          <div className="button button-main" onClick={openSellerModal}>
            <p className='button-title'>Get a Quote!</p>
            <p>and we will get back to you with a valuation</p>
          </div>
          <div className="button button-secondary" onClick={openInvestorModal}>
            <p className='button-title'>I am an Investor!</p>
            <p>and I'm looking for properties</p>
          </div>
        </div>
      </div>
      {showInvestorModal || showSellerModal ? <Backdrop onCancel={closeModals}/> : null}
      {showInvestorModal && (<InvestorForm onCancel={closeModals} onInvestorFormCompleted={newInvestorFormHandler}/>)}
      {showSellerModal && (<SellerForm onCancel={closeModals} onSellerFormCompleted={newSellerForm}/>)}
      <Footer />
    </div>
  );
}

export default App;
